<template>
  <div
    class="mobile-header-root"
    id="mobile-header-root"
    :class="{
      'fixed':isFixedHeader,
      'home': isHome
    }"
  >
    <div
      v-show="!isShowNavBar"
      class="mobile-header"
      :class="{
        'mobile-header_lang': !isOpenLangBar
      }"
    >
      <div class="mobile-header__logo">
        <NuxtLink
          to="/"
        >
          <div>
            <img
              src="~/assets/img/header/palm.svg"
              alt="palm"
            >
            <img
              src="~/assets/img/header/mobile-logo.svg"
              alt="logo"
            >
          </div>
        </NuxtLink>
      </div>
      <div class="controls">
        <LangToggle @changeOpen="() => toggleLangBar()"  />

        <NuxtLink
          :to="localePath(linkForAuth)"
          class="control-btn"
        >
          <client-only :fallback="fallbackCabinetButton">
            {{ isLoggedIn ? $t('account.loggenIn') : $t('account.loggenOut') }}
          </client-only>
          <img
            src="/img/icons/icon-arrow-right-black.svg"
            alt="arrow-right"
          />
        </NuxtLink>
        <button
          class="control-btn burger-menu"
          @click="toggleNavBar()"
        >
          <img
            src="~/assets/img/header/menu-burger.svg"
            alt="menu"
          >
        </button>
      </div>
    </div>
    <div
      v-show="isShowNavBar"
      class="mobile-header_open"
    >
      <div class="mobile-header">
        <div class="mobile-header__logo">
          <NuxtLink
            to="/"
          >
            <div>
              <img
                src="~/assets/img/header/palm.svg"
                alt="palm"
              >
              <img
                src="~/assets/img/header/mobile-logo.svg"
                alt="logo"
              >
            </div>
          </NuxtLink>
        </div>
        <div class="controls">
          <LangToggle />

          <NuxtLink
            :to="localePath(linkForAuth)"
            class="control-btn"
          >
            <client-only :fallback="fallbackCabinetButton">
              {{ isLoggedIn ? $t('account.loggenIn') : $t('account.loggenOut') }}
            </client-only>
            <img
                src="/img/icons/icon-arrow-right-black.svg"
                alt="arrow-right"
            />
          </NuxtLink>
          <button
            class="control-btn burger-menu"
            @click="toggleNavBar()"
          >
            <img
              src="~/assets/img/header/close-icon.svg"
              alt="menu"
            >
          </button>
        </div>
      </div>
      <nav
        class="mobile-header__menu"
      >
        <template
          v-for="(item,idx) in menu"
          :key="`nav-menu-${idx}`"
        >
          <nuxt-link
            v-if="item.isShow"
            :to="item.link !== '/#' ? item.link : item.link + item.id"
          >
            {{ item.name }}
          </nuxt-link>
        </template>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserAuth } from '~/composables/user/useUserAuth';
import LangToggle from '~/components/widgets/language-toggle/LangToggle.vue';
import { useI18n } from '#imports';

const localePath = useLocalePath();

const { t, locale } = useI18n();

const { isLoggedIn } = useUserAuth();

const menu = computed(() => {
  return [
    {
      id: 'getSms',
      name: t('menu.getSms'),
      link: '/#',
      isShow: true
    },
    {
      id: 'earnMoney',
      name: t('menu.earnMoney'),
      link: '/#',
      isShow: true
    },
    {
      name: t('menu.doc'),
      link: localePath('/cabinet/api'),
      isShow: true
    },
    {
      id: '',
      name: t('menu.blog'),
      link: blogUrl.value,
      isShow: true
    }
  ];
});
const $route = useRoute();

const isHome = computed((): boolean => $route.fullPath === '/' || $route.fullPath === '/ru' || $route.fullPath === '/#earnMoney' || $route.fullPath === '/#getSms');
const linkForAuth = computed(() => {
  return isLoggedIn ? '/cabinet' : '/authorization';
});

const isOpenLangBar = ref(true)

const toggleLangBar = () => {
  console.log(`isOpenLangBar.value: ${isOpenLangBar.value }`)
  isOpenLangBar.value = !isOpenLangBar.value;
}

const isShowNavBar = ref(false);

const toggleNavBar = () => {
  isShowNavBar.value = !isShowNavBar.value;
};

const headerHeight = ref(613);
const isFixedHeader = ref<boolean>(false);

function handleScroll (event: Event) {
  headerHeight.value = document.getElementById('mobile-header-root')?.offsetHeight || 0;
  const isFixedCheck = ref(((event?.target as Document).scrollingElement?.scrollTop || 0) > 300);

  if (isFixedHeader.value !== isFixedCheck.value) {
    isFixedHeader.value = isFixedCheck.value;
  }
}

const fallbackCabinetButton = computed(() => {
  if (locale.value === 'en') {
    return 'Sign in'
  }
  if (locale.value === 'ru') {
    return 'Войти'
  }
})

const blogUrl = computed(() => {
  return locale.value === 'en' ? 'https://sms-code.ru/blog/' : 'https://sms-code.ru/blog/ru'
})

// Если хотим прилиппающий хедер, то вернуть
// onMounted(() => {
//   window.addEventListener('scroll', handleScroll);
// });
// onUnmounted(() => {
//   window.removeEventListener('scroll', handleScroll);
// });
</script>

<style scoped lang="scss">
.mobile-header-root {
  position: relative;
  // top: 0;
  // left: 0;
  padding: 10px;
  height: auto;
  width: 100%;
  z-index: 11;
  background-color: var(--primary);
  box-shadow:  0 5px 10px 0 rgb(46 145 235 / 5%);

  &.home {
		position: absolute;
		background-color: transparent;
    box-shadow: none;
	}

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary);
    box-shadow: 0 5px 10px 0 rgb(46 145 235 / 5%);
    //transition: background-color .2s linear;
  }
}
.mobile-header {
  padding: 5px;
  display: flex;
  justify-content: space-between;

  .mobile-header__logo {
    display: flex;
    width: 148px;
    height: 28px;
    align-items: center;

    & * > div {
      display: flex;
    }
  }

  .controls {
    display: flex;
    gap: 12px;
  }

  .control-btn {
    min-width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 12px;
		font-size: 13px;
		line-height: 100%;
		font-weight: 500;
		border-radius: 30px;
		background: rgba(255, 255, 255, 0.24);
		cursor: pointer;
    gap: 5px;

    &.burger-menu {
      min-width: 28px;
      border-radius: 10px;
    }
  }
}
.mobile-header_lang {
  padding: 10px 10px 45px;
}
.mobile-header_open {
  background: rgba(255, 255, 255, 0.24);
  border-radius: 10px;
  //animation: color 0.15s linear;
  //@keyframes color {
  //  from {
  //    background: rgba(255, 255, 255, 0);
  //  } to {
  //    transform: rgba(255, 255, 255, 0.24);
  //  }
  //}

  & .mobile-header {
    //padding: 5px;
  }

}
.mobile-header__menu {
  display: flex;
  font-size: 11px;
  font-weight: 700;
  color: white;
  padding: 26px 13px 20px;
  justify-content: space-between;
  animation: downing 0.15s linear ;
  background-color: rgba(255, 255, 255, 0.06);
  @keyframes downing {
    from {
      transform: translateY(-2vh);
    } to {
      transform: translateY(0);
    }
  }
}
@media screen and (max-width: 360px) {
  .mobile-header-root {
    padding-top: 20px;
  }
  .mobile-header {
    display: block;
  }
  .mobile-header__logo {
    display: flex;
    width: 50px;
    height: 28px;
    position: absolute;
    top: 0;
  }
  .controls {
    justify-content: space-between;
  }
}
</style>
